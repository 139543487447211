import React from 'react';
import Helmet from 'react-helmet';
import {Loader, NotFound} from '@deity/falcon-ecommerce-uikit';
import {CMSPage, StorePage, SalesEventsPage} from 'benlux-ecommerce-ui';
import {CmsPageQuery} from '../../queries/CmsPageQuery';
import {MenuQuery} from "../../queries/MenuQuery";

const Cms = ({id, path, history}) => {

  return (
    <CmsPageQuery variables={{id}}>
      {({cmsPage, loading}) => {
        if (!cmsPage && loading) {
          return <Loader/>;
        }

        const {template, metaTitle, metaDescription} = cmsPage;
        const description = metaDescription || '';
        const title = metaTitle || '';

        return (
          <>
            {!template &&
              <>
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description}/>
                  <meta name="page-loaded" content="true" />
                  <meta property="og:title" content={title + ' ‣ BENLUX'}/>
                  <meta property="og:type" content="website"/>
                  <meta property="og:description" content={description}/>
                  <meta property="og:url" content={'https://www.benlux.fr' + path}/>
                  <meta name="twitter:card" content="summary_large_image"/>
                  <meta property="twitter:domain" content="benlux.fr"/>
                  <meta property="twitter:url" content={'https://www.benlux.fr' + path}/>
                  <meta name="twitter:title" content={title + ' ‣ BENLUX'}/>
                  <meta name="twitter:description" content={description}/>
                </Helmet>
                <CMSPage {...cmsPage}/>
              </>
            }
            {template === "store" &&
              <MenuQuery>
                {({menu}) => {
                  const content = JSON.parse(cmsPage.content);
                  return (
                    <>
                      <Helmet>
                        <title>{metaTitle}</title>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content={content.title + " ‣ BENLUX"}/>
                        <meta property="og:image" content={content.gallery[0].image.url}/>
                        <meta property="og:description" content={content.description}/>
                        <meta property="og:url" content={'https://www.benlux.fr' + path}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta property="twitter:domain" content="benlux.fr"/>
                        <meta property="twitter:url" content={'https://www.benlux.fr' + path}/>
                        <meta name="twitter:title" content={content.title + " ‣ BENLUX"}/>
                        <meta name="twitter:description" content={content.description}/>
                        <meta name="twitter:image" content={content.gallery[0].image.url}/>
                        <meta name="page-loaded" content="true" />
                      </Helmet>
                      <StorePage
                        categories={menu}
                        contents={JSON.parse(cmsPage.content)}
                      />
                    </>
                  )
                }}
              </MenuQuery>
            }
            {template === "sales-events" &&
              <MenuQuery>
                {({menu}) => {
                  const content = JSON.parse(cmsPage.content);

                  return (
                    <>
                      <Helmet>
                        <title>{metaTitle}</title>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content={content.title + " ‣ BENLUX"}/>
                        <meta property="og:image" content={content.image.url}/>
                        <meta property="og:description" content={content.description}/>
                        <meta property="og:url" content={'https://www.benlux.fr' + path}/>
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta property="twitter:domain" content="benlux.fr"/>
                        <meta property="twitter:url" content={'https://www.benlux.fr' + path}/>
                        <meta name="twitter:title" content={content.title + " ‣ BENLUX"}/>
                        <meta name="twitter:description" content={content.description}/>
                        <meta name="twitter:image" content={content.image.url}/>
                        <meta name="page-loaded" content="true" />
                      </Helmet>
                      <SalesEventsPage content={content}/>
                    </>
                  )
                }}
              </MenuQuery>
            }
          </>
        );
      }}
    </CmsPageQuery>
  )
};

export default Cms;
